import { template as template_b15e75830277425f984930dba8b64053 } from "@ember/template-compiler";
const WelcomeHeader = template_b15e75830277425f984930dba8b64053(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
