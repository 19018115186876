import { template as template_29f19add82134f638f92647b57cf54dc } from "@ember/template-compiler";
const FKText = template_29f19add82134f638f92647b57cf54dc(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
