import { template as template_369f6a4c998e4484a21df7b80a81f1e4 } from "@ember/template-compiler";
const FKControlMenuContainer = template_369f6a4c998e4484a21df7b80a81f1e4(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
