import { template as template_60b2780cdd7e4efea40561c60fb02ffe } from "@ember/template-compiler";
import { eq } from "truth-helpers";
const FKControlConditionalContentItem = template_60b2780cdd7e4efea40561c60fb02ffe(`
  {{#if (eq @name @activeName)}}
    <div class="form-kit__conditional-display-content">
      {{yield}}
    </div>
  {{/if}}
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlConditionalContentItem;
